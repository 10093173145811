import { Box } from "@mui/material";
import DashboardCard from "../../components/dashboardCard/DashboardCard";
import { useNavigate } from "react-router-dom";

const headColumnList = {
  columns: [
    { value: "Название лекала", dataKey: "name" },
    { value: "Время обновления", dataKey: "refreshTime" },
  ]
}

const bodyData = [
  {name: "1", refreshTime: "11"},
  {name: "2", refreshTime: "11"},
  {name: "3", refreshTime: "11"},
]

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <Box p={2} sx={{
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
      gridGap: "1em",
    }}>
      <DashboardCard
        title="Количество всего"
        moreBtn={{
          name: "",
          onClick: () => {navigate(`/statistic/device`)}
        }}
        table
        columnData={headColumnList}
        bodyData={bodyData}
      />
      <DashboardCard
        title="Количество онлайн"
        moreBtn={{
          name: "",
          onClick: () => {navigate(`/statistic/device`)}
        }}
        columnData={headColumnList}
      />
      <DashboardCard
        title="Количество резов за сегодня"
        moreBtn={{
          name: "",
          onClick: () => {}
        }}
        columnData={headColumnList}
      />
      <DashboardCard
        title="Лекала"
        moreBtn={{
          name: "",
          onClick: () => {navigate(`/statistic/pattern`)}
        }}
        columnData={headColumnList}
      />
      <DashboardCard
        title="Проблемы с ПО"
        moreBtn={{
          name: "",
          onClick: () => {navigate(`/statistic/device`)}
        }}
        columnData={headColumnList}
      />
      <DashboardCard
        title="Версия системы"
        moreBtn={{
          name: "",
          onClick: () => {}
        }}
        columnData={headColumnList}
      />
      <DashboardCard
        title="Версия прошивки"
        moreBtn={{
          name: "",
          onClick: () => {}
        }}
        columnData={headColumnList}
      />
    </Box>
  )
}

export default Dashboard