import { Box, css, styled } from "@mui/material";


export const MainContainer = styled(Box)`
  ${({ theme }) => css`
    padding: 16px 28px;
    display: flex;
    height: 100vh;
    background-color: ${theme.color.bg.main};
  `}
`;

export const ContentContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: ${theme.color.white["600"]};
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  `}
`;

export const SidebarContainer = styled(Box)`
  ${({ theme }) => css`
    display: block;
    width: 280px;
    flex-shrink: 0;
    border-right: 1px solid ${theme.color.black["200"]};
  `}
`;

export const BodyContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const Content = styled(Box)`
  position: relative;
  flex: 1;
  overflow: hidden;
`;

export const Header = styled('div')({
  width: "100%",
  height: "50px",
  marginBottom: "6px",
  backgroundColor: "#fff",
  padding: "20px",
})

export const Tabs = styled('div')({
  width: "100%",
  height: "30px",
  marginBottom: "6px",
  backgroundColor: "#fff"
})