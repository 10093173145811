import { FC, SyntheticEvent } from "react";
import { SideBarItemContainer } from "./styles";
import { SideBarDataItem } from "./SideBar";
import SideBarAccordion from "./SideBarAccordion";
import { Link, useLocation } from "react-router-dom"

interface Props extends SideBarDataItem {
  expanded: boolean,
  handleChange: (id: string) => (event: SyntheticEvent, newExpanded: boolean) => void
}

const SideBarItem: FC<Props> = ({
  id,
  title,
  Icon,
  accordionData,
  expanded,
  route = "/",
  handleChange,
  disabled
}) => {
  const location = useLocation();

  if (accordionData) return (
    <SideBarAccordion title={title} Icon={Icon} expanded={expanded} onChange={handleChange(id)}>
      {accordionData.map(item => (
        <Link to={item.route} style={{ pointerEvents: item.disabled? "none" : "auto" }}>
          <SideBarItemContainer sx={{
            ...!item.disabled && location.pathname === item.route ? {
              backgroundColor: "#bbb",
            } : {},
            ...item.disabled ? {
              color: "#ccc"
            } : {}
          }}>
            <span>{item.title}</span>
          </SideBarItemContainer>
        </Link>
      ))}
    </SideBarAccordion>
  )

  return (
    <Link to={route} style={{ pointerEvents: disabled? "none" : "auto" }}>
      <SideBarItemContainer
        sx={{
          ...location.pathname === route ? {
            backgroundColor: "#bbb",
          } : {},
          ...disabled ? {
            color: "#ccc"
          } : {}
        }}>
        <Icon /> <span style={{ marginLeft: "14px" }}>{title}</span>
      </SideBarItemContainer>
    </Link>
  )
}

export default SideBarItem