import { FC } from "react";
import { BaseTableName } from "./styles";

interface TableNameProp extends React.PropsWithChildren {
  name?: string| number;
  nameColor?: string;
  id?: string;
  quantity?: number;
  subcolumn?: boolean;
}

export const TableName: FC<TableNameProp> = ({
  name,
  nameColor,
  id,
  quantity,
  subcolumn,
}) => (
  <BaseTableName
    id={id}
    namecolor={nameColor}
    subcolumn={subcolumn ? 1 : 0}
    variant="smallTextSemiBold"
    whiteSpace="normal"
  >
    {name} {quantity && `(${quantity})`}
  </BaseTableName>
);
