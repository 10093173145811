import { createTheme } from "@mui/material";
import { Theme as MaterialUITheme } from "@mui/material/styles";

export const mainTheme = createTheme({
  // TODO: add typography after stable ui-kit
  typography: {
    fontFamily: ["'Inter'", "sans-serif"].join(","),
    textSemiBold: {
      font: "600 14px/24px 'Inter',sans-serif",
    },
    textSemiBoldReduced: {
      font: "600 14px/20px 'Inter',sans-serif",
    },
    textMedium: {
      font: "500 14px/16px 'Inter',sans-serif",
    },
    textRegular: {
      font: "400 14px/24px 'Inter',sans-serif",
    },
    smallTextSemiBold: {
      font: "600 12px/20px 'Inter',sans-serif",
    },
    smallTextRegular: {
      font: "400 12px/20px 'Inter',sans-serif",
    },
    smallTextRegularUltraBold: {
      font: "800 12px/20px 'Inter',sans-serif",
    },
    smallTextRegularReduced: {
      font: "400 12px/16px 'Inter',sans-serif",
    },
    tinyTextRegular: {
      font: "400 10px/12px 'Inter',sans-serif",
    },
  },
  spacing: (factor: number) => `${factor * 6}px`,
  color: {
    black: {
      600: "#212121",
      500: "#666666",
      400: "#BDBDBD",
      300: "#E0E0E0",
      200: "#E9E9E9",
      100: "#FAFAFA",
    },
    red: {
      600: "#D5523E",
      100: "#FBEDEB",
    },
    bg: {
      main: "#F6F6F6",
    },
    green: {
      600: "#5BB64D",
      500: "#11CA18",
    },
    violet: {
      600: "#B420DB",
    },
    white: {
      600: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 720,
      tablet: 920,
      laptop: 1024,
      desktop: 1280,
    },
  },
});

// Re-declare the emotion theme to have the properties of the MaterialUiTheme
declare module "@emotion/react" {
  export interface Theme extends MaterialUITheme {
    color: {
      black: {
        600: React.CSSProperties["color"];
        500: React.CSSProperties["color"];
        400: React.CSSProperties["color"];
        300: React.CSSProperties["color"];
        200: React.CSSProperties["color"];
        100: React.CSSProperties["color"];
      };
      red: {
        600: React.CSSProperties["color"];
        100: React.CSSProperties["color"];
      };
      bg: {
        main: React.CSSProperties["color"];
      };
      green: {
        600: React.CSSProperties["color"];
        500: React.CSSProperties["color"];
      };
      violet: {
        600: React.CSSProperties["color"];
      };
      white: {
        600: React.CSSProperties["color"];
      };
    };
  }
}
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }

  interface Theme {
    color: {
      black: {
        600: React.CSSProperties["color"];
        500: React.CSSProperties["color"];
        400: React.CSSProperties["color"];
        300: React.CSSProperties["color"];
        200: React.CSSProperties["color"];
        100: React.CSSProperties["color"];
      };
      red: {
        600: React.CSSProperties["color"];
        100: React.CSSProperties["color"];
      };
      bg: {
        main: React.CSSProperties["color"];
      };
      green: {
        600: React.CSSProperties["color"];
        500: React.CSSProperties["color"];
      };
      violet: {
        600: React.CSSProperties["color"];
      };
      white: {
        600: React.CSSProperties["color"];
      };
    };
  }

  interface ThemeOptions {
    color: {
      black: {
        500: React.CSSProperties["color"];
        400: React.CSSProperties["color"];
        300: React.CSSProperties["color"];
        200: React.CSSProperties["color"];
        600: React.CSSProperties["color"];
        100: React.CSSProperties["color"];
      };
      red: {
        600: React.CSSProperties["color"];
        100: React.CSSProperties["color"];
      };
      bg: {
        main: React.CSSProperties["color"];
      };
      green: {
        600: React.CSSProperties["color"];
        500: React.CSSProperties["color"];
      };
      violet: {
        600: React.CSSProperties["color"];
      };
      white: {
        600: React.CSSProperties["color"];
      };
    };
  }

  interface TypographyVariants {
    textSemiBold: React.CSSProperties & { font?: string };
    textSemiBoldReduced: React.CSSProperties & { font?: string };
    textMedium: React.CSSProperties & { font?: string };
    textRegular: React.CSSProperties & { font?: string };
    smallTextSemiBold: React.CSSProperties & { font?: string };
    smallTextRegular: React.CSSProperties & { font?: string };
    smallTextRegularUltraBold: React.CSSProperties & { font?: string };
    smallTextRegularReduced: React.CSSProperties & { font?: string };
    tinyTextRegular: React.CSSProperties & { font?: string };
  }

  interface TypographyVariantsOptions {
    textSemiBold: React.CSSProperties & { font?: string };
    textSemiBoldReduced: React.CSSProperties & { font?: string };
    textMedium: React.CSSProperties & { font?: string };
    textRegular: React.CSSProperties & { font?: string };
    smallTextSemiBold: React.CSSProperties & { font?: string };
    smallTextRegular: React.CSSProperties & { font?: string };
    smallTextRegularUltraBold: React.CSSProperties & { font?: string };
    smallTextRegularReduced: React.CSSProperties & { font?: string };
    tinyTextRegular: React.CSSProperties & { font?: string };
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    textSemiBold: true;
    textSemiBoldReduced: true;
    textMedium: true;
    textRegular: true;
    smallTextSemiBold: true;
    smallTextRegular: true;
    smallTextRegularReduced: true;
    tinyTextRegular: true;
  }
}
