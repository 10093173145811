import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DeviceStatistic from "./pages/statistic/device/DeviceStatistic";
import Dashboard from "./pages/dashboard/Dashboard";
import { ThemeProvider } from "@mui/material";
import { mainTheme } from "./styles/theme/mainTheme";
import PatternStatistic from "./pages/statistic/pattern/PatternStatistic";
import MainLayout from "./layouts/mainLayout/MainLayout";
import PatternInfo from "./pages/statistic/pattern/PatternInfo";

const router = createBrowserRouter([
  {
    element: <MainLayout/>,
    children: [
      {
        path: "/",
        element: <Dashboard/>,
      },
      {
        path: "/statistic/device",
        element: <DeviceStatistic/>,
      },
      {
        path: "/statistic/pattern",
        element: <PatternStatistic/>,
      },
      {
        path: "/statistic/pattern/:id",
        element: <PatternInfo/>,
      },
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={mainTheme}>
        <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
