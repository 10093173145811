import { styled } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const SideBarContainer = styled(('div'), {
  shouldForwardProp: (prop) => prop !== 'collapse',
})<{ collapse?: boolean }>(({ collapse }) => ({
  height: "100%",
  width: collapse ? "54px" : "280px",
  position: "absolute",
}))

export const SideBarItemContainer = styled('div')({
  height: "56px",
  width: "240px",
  display: "flex",
  alignItems: "center",
  padding: "0 15px",
  "&:hover:not(.MuiAccordionSummary-content > &)": {
    backgroundColor: "#aaa",
  }
})


export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(({ theme }) => ({
  height: "56px",
  paddingLeft: 0,
  "&:hover": {
    backgroundColor: "#aaa",
  }
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));