import Table from "../../../components/table/Table";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { redirect, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { useState } from "react";
import { toLocalDate } from "../../../utility/toLocalDate";
import { adminService, patternDomain } from "../../../api/baseUrl";
import { Header } from "../../../layouts/mainLayout/styles";

// @ts-ignore
const fetcher = (...args: any) => fetch(...args).then((res) => res.json())

const patternColumnData = {
  columns: [
    { value: "ID", dataKey: "id" },
    { value: "Название устройства", dataKey: "name" },
    { value: "Серия устройства", dataKey: "deviceSeriesName" },
    { value: "Статус", dataKey: "statusName" },
    { value: "Формат", dataKey: "formatName" },
    { value: "Дата загрузки файла в БД", dataKey: "fileCreatedAt", prepareData: toLocalDate },
    { value: "Комментарий", dataKey: "comment" },
    { value: "Ширина", dataKey: "width" },
    { value: "Высота", dataKey: "height" },
    { value: "Количество резов за 140 дней", dataKey: "count140" },
    { value: "Тип устройства", dataKey: "deviceTypeName" },
    { value: "Сторона устройства", dataKey: "sideName" },
    { value: "Типоразмер лекала", dataKey: "patternSizeName" },
    { value: "Вырезы", dataKey: "cutoutName" },
    { value: "Второй экран", dataKey: "secondScreen" },
    { value: "Лекало 360", dataKey: "pattern360Name" },
    { value: "Логотип", dataKey: "logo" },
    { value: "Блок камеры", dataKey: "cameraBlock" },
    { value: "Цветная печать", dataKey: "suitableColor" },
    { value: "Прозрачная", dataKey: "suitableClear" },
    { value: "Типоразмер заготовки", dataKey: "sizeName" },
    { value: "Показывать в конфигураторе Skin2.ru", dataKey: "showConfigurator" },
  ]
}

const PatternStatistic = () => {
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(0);

  const { data, error } = useSWR(`${adminService}/api/pattern?page=${pageIndex}`, fetcher)

  const patternColumnDataWithActions = {
    ...patternColumnData,
    actions: [
      {
        icon: EditIcon,
        onClick: (id: string) => {
          window.open(`${patternDomain}?patternId=${id}`, '_blank');
        },
      },
      {
        icon: VisibilityIcon,
        onClick: (id: string) => {
          console.log(id)
          navigate(`/statistic/pattern/${id}`)
        },
      }
    ]
  }

  return (
    <>
      <Header>Список всех лекал</Header>
      <Table
        bodyData={data?.content}
        columnData={patternColumnDataWithActions}
        pagination={{
          count: data?.totalPages,
          page: data?.number + 1,
          handleChangePage: (e, page) => setPageIndex(page - 1),
        }}
      />
    </>
  )
}

export default PatternStatistic