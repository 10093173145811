import { Box, Button, Paper, Table, Typography } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { FC } from "react";
import BasicTable, { TableProps } from "../table/Table";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

interface Props extends TableProps{
  title: string,
  moreBtn: {
    name?: string,
    onClick: () => void
  }
  table?: boolean
}

const DashboardCard:FC<Props> = ({
  title,
  moreBtn,
  table,
  columnData,
  bodyData
}) => {
  return (
    <Paper>
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#eee",
          paddingX: 1
      }}>
        <Typography>{title}</Typography>
        <Button onClick={moreBtn.onClick}>{moreBtn.name || "Подробнее"}</Button>
      </Paper>
      {table &&
          <TableContainer component={Box}>
        <Table sx={{ minWidth: 300 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columnData.columns.map((column, key) =>
                <TableCell>{column.value}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyData?.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {columnData.columns.map(cell => <TableCell>{row[cell.dataKey]}</TableCell>)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }
    </Paper>
  )
}

export default DashboardCard;