import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { toLocalDate } from "../../../utility/toLocalDate";
import useSWR from "swr";
import { adminService } from "../../../api/baseUrl";
import { Header } from "../../../layouts/mainLayout/styles";

// @ts-ignore
const fetcher = (...args: any) => fetch(...args).then((res) => res.json())

const patternInfoFields = [
  { value: "ID", dataKey: "id" },
  { value: "Название устройства", dataKey: "name" },
  { value: "Серия устройства", dataKey: "deviceSeriesName" },
  { value: "Статус", dataKey: "statusName" },
  { value: "Формат", dataKey: "formatName" },
  { value: "Дата загрузки файла в БД", dataKey: "fileCreatedAt", prepareData: toLocalDate },
  { value: "Комментарий", dataKey: "comment" },
  { value: "Ширина", dataKey: "width" },
  { value: "Высота", dataKey: "height" },
  { value: "Количество резов за 140 дней", dataKey: "count140" },
  { value: "Тип устройства", dataKey: "deviceTypeName" },
  { value: "Сторона устройства", dataKey: "sideName" },
  { value: "Типоразмер лекала", dataKey: "patternSizeName" },
  { value: "Вырезы", dataKey: "cutoutName" },
  { value: "Второй экран", dataKey: "secondScreen" },
  { value: "Лекало 360", dataKey: "pattern360Name" },
  { value: "Логотип", dataKey: "logo" },
  { value: "Блок камеры", dataKey: "cameraBlock" },
  { value: "Цветная печать", dataKey: "suitableColor" },
  { value: "Прозрачная", dataKey: "suitableClear" },
  { value: "Типоразмер заготовки", dataKey: "sizeName" },
  { value: "Показывать в конфигураторе Skin2.ru", dataKey: "showConfigurator" },
]

const PatternInfo = () => {
  const params = useParams();

  const { data, error } = useSWR(`${adminService}/api/pattern/${params?.id}`, fetcher)

  return(
<>
  <Header>Инфомация по лекалу</Header>
  <Box p={2}>
    {patternInfoFields.map(field =>
      // @ts-ignore
      <Typography mb={1}>{field.value + ": " + (data?.[field.dataKey] || "") }</Typography>
    )}
  </Box>
</>
  )
}

export default PatternInfo