import * as React from 'react';
import { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  ExtendedTableWrapper,
  PaperStyled,
  TableCellBodyName, TableCellFooter,
  TableCellHead,
  TableCellName,
  TableCell,
  TypographyTruncated
} from "./styles";
import { TableName } from "./tableName";
import { Box, Pagination, Paper, TablePagination } from "@mui/material";

export interface TableProps {
  columnData: {
    columns: { value: string, dataKey: string, prepareData?: (data: any) => any }[]
    actions?: any[]
  }
  bodyData?: any[],
  pagination?: {
    count: number,
    page: number,
    handleChangePage: (event: React.ChangeEvent<unknown>, page: number) => void
  }
}

const BasicTable: FC<TableProps> = ({
  columnData,
  bodyData = [],
  pagination
}) => {
  return (
    <Paper sx={{ width: '100%', height: "100%", overflow: 'hidden', display: "flex", flexDirection: "column" }}>
      <TableContainer component={PaperStyled}>
        <Table stickyHeader sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {columnData.columns.map(
                (column, key) => {
                  if (key === 0) {
                    return (
                      <TableCellName key={key}>
                        <TypographyTruncated>{column.value}</TypographyTruncated>
                      </TableCellName>
                    )
                  }

                  return (
                    <TableCellHead key={key}>
                      <TypographyTruncated>{column.value}</TypographyTruncated>
                    </TableCellHead>
                  )
                }
              )}
              {columnData.actions &&
                  <TableCellFooter>
                      <TypographyTruncated>Действия</TypographyTruncated>
                  </TableCellFooter>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyData.map((rowData) => (
              <TableRow key={rowData.id}>
                {columnData.columns.map((cell, key) => {
                  let content = rowData[cell.dataKey]
                  if (cell.prepareData) {
                    content = cell.prepareData(content);
                  }
                  if (typeof content === "boolean") content = content ? "да" : "нет";
                  if (content === 0) content = "0";
                  content = content || ""
                  if (key === 0) {
                    return (
                      <TableCellBodyName
                        key={key}
                        component="th"
                        scope="content"
                      >
                        <TableName
                          name={content}
                        />
                      </TableCellBodyName>
                    );
                  }

                  return (
                    <TableCell key={key} >
                      <ExtendedTableWrapper>
                        <TableName
                          name={content}
                        />
                      </ExtendedTableWrapper>
                    </TableCell>
                  );
                })}
                {columnData.actions &&
                    <TableCellFooter>
                        <Box p={2}>
                          {columnData.actions.map((action, key) => {
                            const Icon = action.icon
                            return <Icon onClick={() => action.onClick(rowData.id)}/>
                          })}
                        </Box>
                    </TableCellFooter>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && <Pagination
          count={pagination.count}
          page={pagination.page}
          onChange={pagination.handleChangePage}
      />}
    </Paper>
  );
}

export default BasicTable