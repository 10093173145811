import { Box, IconButton, styled, useTheme } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from '@mui/icons-material/BarChart';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';
import React, { FC, SyntheticEvent, useState } from "react";
import SideBarItem from "./SideBarItem";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export type SideBarDataItem = {
  id: string,
  title: string,
  Icon: FC,
  disabled?: boolean,
  route?: string,
  accordionData?: {
    disabled?: boolean,
    title: string,
    route: string
  }[]
}

type SideBarData = SideBarDataItem[]

const sideBarData: SideBarData = [
  {
    id: "main",
    title: "Главная страница",
    route: "/",
    Icon: HomeIcon,
  },
  {
    id: "statistic",
    title: "Статистика",
    Icon: BarChartIcon,
    accordionData: [
      {
        title: "Список всех устройств",
        route: "/statistic/device"
      },
      {
        title: "Список всех лекал",
        route: "/statistic/pattern"
      },
      {
        title: "Статистика резов",
        route: "/",
        disabled: true,
      },
      {
        title: "Протоколы",
        route: "/",
        disabled: true,
      },
    ]
  },
  {
    id: "admin",
    title: "Администрирование системы",
    Icon: AdminPanelSettingsIcon,
    accordionData: [
      {
        title: "Управление пользователями",
        route: "/",
        disabled: true,
      },
    ]
  },
  {
    id: "settings",
    title: "Настройки устройств",
    Icon: SettingsIcon,
    accordionData: [
      {
        title: "Устройства",
        route: "/",
        disabled: true,
      },
      {
        title: "Удалённый доступ к устройству",
        route: "/",
        disabled: true,
      },
      {
        title: "Добавить новое",
        route: "/",
        disabled: true,
      },
      {
        title: "ПО плоттеров",
        route: "/",
        disabled: true,
      },
      {
        title: "Разрешения",
        route: "/",
        disabled: true,
      },
    ]
  }
]

// @ts-ignore
const SB = styled('div')(({ theme, open }) => ({
  width: open? "280px" : "54px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden'
}));

const SideBar: FC = () => {
  const [expanded, setExpanded] = useState<string>("");
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(prev => !prev);
  };

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      if (!open) setOpen(true);
      setExpanded(newExpanded ? panel : "");
    };

  return (
    // @ts-ignore
    <SB open={open}>
      <Box>
        {sideBarData.map((item => <SideBarItem expanded={open && expanded === item.id}
                                               handleChange={handleChange} {...item} />))}
      </Box>
      <IconButton onClick={handleDrawerToggle} sx={{width: "40px", alignSelf: "end"}}>
        {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </SB>
  )
}

export default SideBar
