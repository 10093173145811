import Table from "../../../components/table/Table";
import useSWR from "swr";
import { useState } from "react";
import { toLocalDate } from "../../../utility/toLocalDate";
import { adminService } from "../../../api/baseUrl";
import { Header } from "../../../layouts/mainLayout/styles";

// @ts-ignore
const fetcher = (...args: any) => fetch(...args).then((res) => res.json())

const headColumnList = {
  columns: [
    { value: "ID", dataKey: "id" },
    { value: "ID в 1С", dataKey: "serialNumber" },
    { value: "Тип устройства", dataKey: "deviceTypeName" },
    { value: "дата активации устройства", dataKey: "createdAt", prepareData: toLocalDate },
    { value: "дата последнего сеанса связи", dataKey: "", prepareData: toLocalDate },
    { value: "версия установленного ПО", dataKey: "" },
    { value: "Всего вырезано на этом устройстве", dataKey: "cutCount" },
    { value: "Ответственный по ОТК", dataKey: "" },
    { value: "Ключ HFA", dataKey: "" },
  ]
}

const DeviceStatistic = () => {
  const [pageIndex, setPageIndex] = useState(0);

  const { data, error } = useSWR(`${adminService}/api/device?page=${pageIndex}`, fetcher)

  return (
    <>
      <Header>Список всех устройств</Header>
      <Table
        bodyData={data?.content || []}
        columnData={headColumnList}
        pagination={{
          count: data?.totalPages,
          page: data?.number + 1,
          handleChangePage: (e, page) => setPageIndex(page - 1),
        }}
      />
    </>
  )
}

export default DeviceStatistic