import {
  styled,
  css,
  TableCell as MuiTableCell,
  Paper,
  Box,
} from "@mui/material";
import Typography from "@mui/material/Typography";

export const PaperStyled = styled(Paper)`
  width: 100%;
  border-radius: 0;
  box-shadow: none;
`;


export const TableCellName = styled(MuiTableCell)`
  ${({ theme }) => css`
    position: sticky;
    left: 0;
    z-index: 4;
    padding: 4px 6px;
    width: 300px;
    min-width: 300px;
    color: ${theme.color.black["600"]};
    background-color: ${theme.color.white["600"]};
    border: 1px solid ${theme.color.black["200"]};
  `};
`;

export const TableCellFooter = styled(MuiTableCell)`
  ${({ theme }) => css`
    position: sticky;
    right: 0;
    z-index: 2;
    padding: 4px 6px;
    width: 50px;
    min-width: 50px;
    vertical-align: top;
    color: ${theme.color.black["600"]};
    background-color: ${theme.color.white["600"]};
    border: 1px solid ${theme.color.black["200"]};
  `};
`;


export const TableCellHead = styled(MuiTableCell)`
  ${({ theme }) => css`
    font: ${theme.typography.smallTextSemiBold.font};
    padding: 4px 6px;
    width: 300px;
    min-width: 300px;
    color: ${theme.color.black["600"]};
    background-color: ${theme.color.white["600"]};
    border: 1px solid ${theme.color.black["200"]};
  `}
`;

export const TypographyTruncated = styled(Typography)`
  ${({ theme }) => css`
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    font: ${theme.typography.smallTextRegularReduced.font};
    color: ${theme.color.black["400"]};
  `}
`;

export const TableCellBodyName = styled(TableCellName)`
  ${({ theme }) => css`
    z-index: 3;
    padding-top: 10px;
    padding-bottom: 0;
    padding-left: 16px;
    padding-right: 16px;
    background-color: ${theme.color.white["600"]};
  `};
`;

export const TableCell = styled(MuiTableCell)`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.black["200"]};
  `};
`;

interface BaseTypographyProp {
  namecolor?: string;
  subcolumn: number;
}

export const BaseTableName = styled(Typography)<BaseTypographyProp>`
  ${({ theme, namecolor, subcolumn }) => css`
    position: relative;
    font: ${subcolumn
  ? theme.typography.smallTextRegular.font
  : theme.typography.smallTextSemiBold.font};
    color: ${theme.color.black["600"]};

    &:before {
      position: absolute;
      top: 50%;
      left: -10px;
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: ${namecolor};
      transform: translateY(-50%);
    }
  `}
`;

export const ExtendedTableWrapper = styled(Box)`
  ${() => css`
    display: flex;
    padding-top: 10px;
  `}
`;