import { Header, MainContainer, ContentContainer, BodyContainer, Content } from "./styles";
import { FC, PropsWithChildren } from "react";
import SideBar from "../../components/sideBar/SideBar";
import { Outlet } from "react-router-dom"

const MainLayout:FC<PropsWithChildren> = ({ children }) => {

  return(
    <MainContainer>
      <ContentContainer>
        <SideBar/>
        <BodyContainer>
          <Content>
            <Outlet/>
          </Content>
        </BodyContainer>
      </ContentContainer>
    </MainContainer>
  )
}

export default MainLayout;